/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The pxToRem() function helps you to convert a px unit into a rem unit, 
 */

function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export default pxToRem;
