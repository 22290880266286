/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// AYC React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Images
import team2 from "assets/images/profile_placeholder.jpg";

export default function data(applications) {
  const Author = ({ id, image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          component="a"
          href={`/applications/${id}`}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Applicant", accessor: "applicant", width: "30%", align: "left" },
      { Header: "Phone", accessor: "mobile", align: "center" },
      { Header: "Nationality", accessor: "nationality", align: "center" },
      { Header: "Residence", accessor: "residence", align: "left" },
      { Header: "Challenge", accessor: "challenge", align: "center" },
      { Header: "Institution", accessor: "institution", align: "center" },
      { Header: "Degree_title", accessor: "degree_title", align: "center" },
    ],

    rows: applications.map((row) => ({
      applicant: <Author id={row.id} image={team2} name={row.name} email={row.email} />,
      mobile: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.mobile}
        </MDTypography>
      ),
      nationality: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.nationality}
        </MDTypography>
      ),
      residence: <Job title={row.country_of_residence} description={row.city} />,
      challenge: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.challenge}
        </MDTypography>
      ),
      institution: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.institution}
        </MDTypography>
      ),
      degree_title: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.degree_title}
        </MDTypography>
      ),
    })),
  };
}
