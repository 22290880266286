/* eslint-disable import/prefer-default-export */
import Utils from "redux/utils";
import ApplicationsActionTypes from "./application.types";

export const fetchApplications = () => ({
  type: ApplicationsActionTypes.FETCH_APPLICATIONS,

  request: { method: "get", url: `/v1/submissions/ayc` },
  meta: {
    onRequest: Utils.onRequest,
    getData: (data) => data,
  },
});

export const fetchApplicationDetails = (id) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_DETAILS,
  request: { method: "get", url: `/v1/submissions/application/${id}` },
  meta: {
    onRequest: Utils.onRequest,
    getData: (data) => ({ ...data }),
  },
});
