/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// AYC React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import { saveAs } from "file-saver";

function DefaultProjectCard({ image, title }) {
  // const renderAuthors = authors.map(({ image: media, name }) => (
  //   <Tooltip key={name} title={name} placement="bottom">
  //     <MDAvatar
  //       src={media}
  //       alt={name}
  //       size="xs"
  //       sx={({ borders: { borderWidth }, palette: { white } }) => ({
  //         border: `${borderWidth[2]} solid ${white.main}`,
  //         cursor: "pointer",
  //         position: "relative",
  //         ml: -1.25,

  //         "&:hover, &:focus": {
  //           zIndex: "10",
  //         },
  //       })}
  //     />
  //   </Tooltip>
  // ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        {/* <MDButton variant="gradient" component="a" href={image} color="secondary" fullWidth>
          Download Image
        </MDButton> */}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DefaultProjectCard;
