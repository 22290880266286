/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// AYC React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// AYC React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
// import Header from "layouts/profile/components/Header";
// import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
// Images
import { useDispatchRequest } from "@redux-requests/react";
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { fetchApplicationDetails } from "redux/application/application.actions";
import { useParams } from "react-router-dom";
import { BACKEND_HOST } from "config";

function ApplicationDetails() {
  const [applicationDetails, setApplicationDetails] = useState({
    birth_date: "dT00",
    languages: {},
  });
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatchRequest = useDispatchRequest();
  useEffect(() => {
    setLoading(true);

    dispatchRequest(fetchApplicationDetails(applicationId))
      .then(({ data }) => {
        console.log(data.languages);
        setLoading(false);
        setApplicationDetails(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {loading && <LinearProgress />}
      {/* <Header> */}
      <MDBox mt={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="applicant informations"
              //   description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: applicationDetails.name,
                mobile: applicationDetails.mobile,
                email: applicationDetails.email,
                nationalty: applicationDetails.country_of_residence,
                location: applicationDetails.country_of_residence,
                city: applicationDetails.city,
                profession: applicationDetails.professional_data,
                birth_date: applicationDetails.birth_date.split("T")[0],
              }}
              //   action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ProfileInfoCard
              title="education informations"
              info={{
                challenge: applicationDetails.challenge,
                country_of_study: applicationDetails.country_of_study,
                institution: applicationDetails.institution,
                higher_secondary: applicationDetails.higher_secondary,
                degree_title: applicationDetails.degree_title,
                graduation_date: applicationDetails.graduation_date,
                gpa: applicationDetails.cgpa_or_equivalent,
                area_of_interest: applicationDetails.area_of_interest,
                describe: applicationDetails.describe,
              }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
          <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ProfileInfoCard
              title="Languages"
              info={{ ...applicationDetails.languages }}
              action={{ route: "", tooltip: "Edit Profile" }}
              shadow={false}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={2} px={2} lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium">
          Documents
        </MDTypography>
        <MDBox mb={1}>
          <MDTypography variant="button" color="text">
            Applicant uploaded Documents
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Passport frontpage
            </MDTypography>
            <DefaultProjectCard
              image={`${BACKEND_HOST}${applicationDetails.passport_frontpage}`}
              title="Passport frontpage"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              passport back
            </MDTypography>
            <DefaultProjectCard
              image={`${BACKEND_HOST}${applicationDetails.passport_backpage}`}
              title="passport back"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              id front
            </MDTypography>
            <DefaultProjectCard
              image={`${BACKEND_HOST}${applicationDetails.personal_id_front}`}
              title="id front"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              id back
            </MDTypography>
            <DefaultProjectCard
              image={`${BACKEND_HOST}${applicationDetails.personal_id_back}`}
              title="id back"
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              Visa
            </MDTypography>
            <DefaultProjectCard image={`${BACKEND_HOST}${applicationDetails.visa}`} title="Visa" />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              CV
            </MDTypography>
            <DefaultProjectCard image={`${BACKEND_HOST}${applicationDetails.cv}`} title="CV" />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <MDTypography variant="h6" fontWeight="medium">
              supporting documents
            </MDTypography>
            <DefaultProjectCard
              image={`${BACKEND_HOST}${applicationDetails.attachment_supporting_documents}`}
              title="supporting documents"
            />
          </Grid>
        </Grid>
      </MDBox>
      {/* </Header> */}
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationDetails;
