/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// AYC React helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";

// AYC React base styles
import colors from "assets/theme-dark/base/colors";
import boxShadows from "assets/theme-dark/base/boxShadows";
import borders from "assets/theme-dark/base/borders";

const { transparent } = colors;
const { md } = boxShadows;
const { borderRadius } = borders;

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: md,
      padding: pxToRem(8),
      borderRadius: borderRadius.md,
    },
  },
};

export default popover;
