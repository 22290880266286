/**
=========================================================
* AYC React - v2.1.0
=========================================================

* Product Page: https://arabyouthcenter.org//product/material-dashboard-react
* Copyright 2022 AYC (https://arabyouthcenter.org/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// AYC React Base Styles
import colors from "assets/theme-dark/base/colors";

const { transparent } = colors;

const iconButton = {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};

export default iconButton;
